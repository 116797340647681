import React from 'react';
import { Flex } from './Flex';
import { makeid } from './utils';
import { useStore } from './store';
type itObj = {
  label: string,
  type: string
}
export default function FieldSet({
  list_form,
  legend_text,
  state_key,
  style,
  required = false,
  NorowWrap = false,
  flexStyleParent = {},
  additionalInfo = false
}) {
  // const snap = useSnapshot(state);
  const { dynamicSetData, ...snap } = useStore((state) => ({
    ...state,
  }));
  // const newString = (char)=>   typeof char === 'string' ? char.replace(/>|</g, '') : it;

  const forName = (char) =>
    typeof char === 'string'
      ? char.replace(/>|</g, '').slice(0, 1).trim() + makeid(4)
      : it;
  const addStyle = NorowWrap ? {'div': {display: 'flex',gap: '5px',justifyContent: 'space-between'},'flex': {},'checkbox':{'width': '13px'}} : { 'flex': {flexWrap: 'wrap'},'div': {display: 'flex',gap: '5px',justifyContent: 'space-between'},'checkbox':{'width': '13px'}}
  return (
    <div style={style}>
      <fieldset>
        <legend>
          {legend_text} {required && <span>*</span>}
        </legend>
        <Flex
          flexDirection={'row'}
          alignItems={'center'}
          style={{
            ...addStyle.flex,
            ...flexStyleParent
          }}>
          {list_form.map((it: itObj) => {
            const new_key = forName(it.label);
            if (it.type === 'text') {
              return (
                <div
                  key={new_key}
                  style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    ...addStyle.div
                  }} >
                  <label
                    htmlFor={`type_${new_key}`}
                    style={{
                      marginRight: '10px'
                    }}>
                    {it.label}:
                  </label>
                  <input
                    type={'text'}
                    name={`type_${new_key}`}
                    onChange={(e) => dynamicSetData(state_key, e.target.value)}
                    value={it['label'] === snap[state_key] ? snap[state_key] : null}
                  />
                </div>
              );
            }

            return (
              <>
                <div key={new_key} style={{
                    ...addStyle.div
                }}>
                  <input
                    type={it.type}
                    name={`type_${new_key}`}
                    id={`type_${new_key}`}
                    onChange={(e) =>
                      dynamicSetData(state_key, e.target?.checked ? it.label : null)
                    }
                    style={{
                    //   width: '15px',
                    ...addStyle.checkbox
                    }}
                    checked={it.label === snap[state_key] ? true : false}
                  />
                  {it.type !== 'text' && (
                    <label htmlFor={`type_${new_key}`}
                    //  onClick={() => dynamicSetData(state_key,snap[state_key] ? it : null)}
                    >{it.label}</label>
                  )}
                </div>
              </>
            );
          })}
          {
            additionalInfo && (
              <div
                  style={{
                    marginLeft: '10px',
                  }}>
                  <label
                    htmlFor={`type_add_${state_key}`}
                    style={{
                      marginRight: '10px',
                    }}>
                    Additional Info
                  </label>
                  <input
                    type={'text'}
                    name={`type_add_${state_key}`}
                    onChange={(e) => {
                      dynamicSetData(`${state_key}_adInfo`, e.target.value)
                    }}
                    value={snap[`${state_key}_adInfo`] ? snap[`${state_key}_adInfo`] : null}
                  />
                </div>
            )
          }
        </Flex>
      </fieldset>
    </div>
  );
}