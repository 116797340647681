export const fetchApi = async (url, methodtype, header, body1) => {



    try {
        const data = await fetch(
            url , {
                method: methodtype,
                headers: header,
                body: body1
            });

        return data.json();
    } catch (e) {
        return new Promise(reject => reject(e));
    }
};


/**
 * Checks if Null or Undefined.
 * @date 2022-08-05
 * @param {?string} str
 * @returns {any}
 */
export function isStringEmpty(str) {
    return Boolean(typeof str === 'string' && str.trim().length === 0);
}


export const list_arr = [{
    type: 'checkbox',
    label: '>20'
},{
    type: 'checkbox',
    label: '20-50'
},{
    type: 'checkbox',
    label: '51-100'
},{
    type: 'checkbox',
    label: '100+'
}]
export const hun_thousand = [{
    type: 'checkbox',
    label: '<500'
}, {
    type: 'checkbox',
    label: '500-2000'
}, {
    type: 'checkbox',
    label: '2000-5000'
}, {
    type: 'checkbox',
    label: '5000+'
}]

export const option_list = [{
    type: 'checkbox',
    label: 'New contact center',
}, {
    type: 'checkbox',
    label: 'Migration from Existing contact center'
},{
    type: 'checkbox',
    label: 'Enhance existing Amazon connect'
},{
    type: 'text',
    label: 'Additional Info'
}]

/**
 * Checks if Null or Undefined.
 * @date 2022-08-05
 * @param {any} value
 * @returns {any}
 */
export function isNullOrUndefined(value) {
    // eslint-disable-next-line no-eq-null, eqeqeq
    return Boolean(value == null);
}

export function makeid (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }
