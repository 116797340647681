import React, { useState } from 'react';
import { Next, Prev, ReactStepForm } from '@ebubechi_ihediwa/react-step-form';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepFour from './StepFour';
import './App.css';
import Center from './Center';
import StepThree from './StepThree';
// import { proxy,useSnapshot  } from 'valtio'
import { fetchApi, isNullOrUndefined, isStringEmpty } from './utils';
import { toast } from 'react-toastify';
import { Flex } from './Flex';
// import { create } from 'zustand'
import { useStore } from './store';

// function unproxify(val)  {
//     if (val instanceof Array) return val.map(unproxify)
//     if (val instanceof Object) return Object.fromEntries(Object.entries(Object.assign({},val)).map(([k,v])=>[k,unproxify(v)]))
//     return val
// }

// const { getState, setState, subscribe } = store

// export default store
// export const state = proxy({
//         "first_name": null,
//         "last_name": null,
//         "email": null,
//         customer_company: null,
//         customer_industry: null,
//         "phone_number": null,
//         "implementation_type": null,
//         "desired_location": null,
//         "agents_location": null,
//         "current_call_center_infra": null,
//         "business_hour": null,
//         "agent_count": null,
//         "agent_support_languages": null,
//         "volume_calls":null,
//         "callback_feature": null,
//         "voicemail_support": null,
//         "outbound_campaign": null,
//         "outbound_sms_email": null,
//         channel_comm: null,
//         require_capacity_planning: null,
//         "capacity_planning": null,
//         "crm_system": null,
//         "compliance_req": null,
//         "data_security": null,
//         "current_flows_count": null,
//         "custom_integration": null,
//         ai_driven_bot: null,
//         "redaction": null,
//         "reporting_analytics": null,
//         "connect_case_integration": null,
//         "voice_auth": null,
//         "go_live_connect": null,
//         "dr_setup_requirements": null,
//         "it_support": null,
//         "port_phn_to_amzn": null,
//         "requirement_training": null,
//         // "customer_name": null,
//         // "customer_industry": null,
//         // "business_hours": null,
//         // "agents_count": null,
//         // "customer_service_training": null,
//         // "workforce_scheduling": null,
//         // "drm_integrations":null,
//         // "flows_count": null,
//         // "dr_setup": null,
//         // "ivr_voice_flows":null,
//         // "language": null,
//         // "locations": null,
//         // "avg_call_volumne": null,
//         // "avg_call_duration": null,
//         // "data_dips": false,
//         // "crm_integrations": false,
//         // "ccp_with_salesforce_zendesk": false,
//         // "chat": false,
//         // "voicemail_setup": null,
//         // "case_task_setup": null,
//         // "phone_numbers_to_port": null,
//         // "other_integrations": null,
//         // "pii_complaint": false,
//         // "sms_text": false,
//         // "disable_Form": false

// })
const required_field = {
  // "first_name": 'Missing First Name Field',
  // "last_name": 'Missing Last Name Field',
  email: 'Missing Email Field',
  customer_company: 'Missing Comapnay Field',
  // customer_industry: 'Missing Industry Field',
  // "phone_number": 'Missing Phone Number Field',
  implementation_type: 'Missing Implementation Type Field',
  // "desired_location": 'Missing Desired Location Field',
  // "agents_location": 'Missing Agents Location Field',
  // "current_call_center_infra": 'Missing current call center infrastructure Field',
  business_hour: 'Missing Business hours Field',
  agent_count: 'Missing agents to support Field',
  agent_support_languages: 'Missing languages to support Field',
  volume_calls: 'Missing Volume Calls Field',
  // "callback_feature": 'Missing  callback feature Field',
  // "voicemail_support": 'Missing voicemail feature Field',
  // "outbound_campaign": 'Missing outbound campaigning requirements Field',
  // "outbound_sms_email": 'Missing outbound SMS and Email requirements Field',
  // channel_comm: 'Missing Communication channel Field',
  require_capacity_planning:
    'Missing Workforce, Scheduling and capacity planning Field',
  // "capacity_planning": 'Missing Workforce, Scheduling and capacity planning Field',
  crm_system: 'Missing CRM systems Field',
  compliance_req: 'Missing Compliance requirement Field',
  // "data_security": 'Missing data security or compliance requirements Field',
  current_flows_count: 'Missing call flows Field',
  third_party: 'Missing Third Party Requirement Field',
  kpi_dashboards: 'Missing kpi metrics Field',
  // "custom_integration": 'Missing custom integration Field',
  // ai_driven_bot: 'Missing AI driven Bots Field',
  // "redaction": 'Missing data redaction Field',
  // "reporting_analytics": 'Missing  reporting or analytics Field',
  // "connect_case_integration": 'Missing Connect Cases/Task integration Field',
  // "voice_auth": 'Missing voice authentication Field',
  go_live_connect: 'Missing  go live with Amazon connect  Field',
  dr_setup_requirements: 'Missing DR requirements Field',
  it_support: 'Missing IT team to support Field',
  port_phn_to_amzn: 'Missing phone numbers to port Field',
  requirement_training: 'Missing agent training Field',
};

// const unsubscribe = subscribe(state,(state) => console.log('subscription state',state))
export default function Form() {
  const [steps, setSteps] = useState(0);
  // const snap = useSnapshot(state)
  const [disable_form, setDisableForm] = useState(false);
  const { dynamicSetData, ...snap } = useStore((state) => ({
    ...state,
  }));
  const noOfSteps = 3;
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast('Form Submission in Progress', {
      toast_type: toast.TYPE.INFO,
      autoClose: false,
    }));

  const update = ({ toast_type, custom_component }) =>
    toast.update(toastId.current, {
      render: custom_component,
      type: toast_type,
      autoClose: 5000,
    });

  // React.useEffect(() => {
  //   return () => {
  //     // unsubscribe()
  //   }
  // })

  const components = [
    <StepOne steps={steps} setSteps={setSteps} noOfSteps={noOfSteps} />,
    <StepTwo steps={steps} setSteps={setSteps} noOfSteps={noOfSteps} />,
    <StepThree steps={steps} setSteps={setSteps} noOfSteps={noOfSteps} />,
    <StepFour steps={steps} setSteps={setSteps} noOfSteps={noOfSteps} />,
  ];

  const submitForm = async (e) => {
    notify();
    e.preventDefault();
    // alert(snap.email);
    // const unprostate = unproxify(state)
    // const new_snap = snap
    const get_form_data = snap;
    console.log('go_live_connect', get_form_data)
    let is_null = Object.keys(required_field).find(
      (it) => isStringEmpty(get_form_data[it]) || get_form_data[it] === null,
    );
    if (is_null) {
      update({
        toast_type: toast.TYPE.ERROR,
        custom_component: required_field[is_null],
      });

      return null;
    } else if (
      !isNullOrUndefined(get_form_data.phone_number) &&
      get_form_data.phone_number.length !== 10
    ) {
      update({
        toast_type: toast.TYPE.ERROR,
        custom_component: 'Invalid Phone Number',
      });

      return null;
    }
    console.log('getFormData', get_form_data);
    // const json_data = Object.assign({
    //     "name": "morpheus",
    //     "job": "leader"},get_form_data)
    fetchApi(
      'https://rrrl5o96sk.execute-api.us-east-1.amazonaws.com/test',
      'post',
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
      },
      JSON.stringify(get_form_data),
    )
      .then(() => {
        update({
          toast_type: toast.TYPE.SUCCESS,
          custom_component: 'FORM SUBMITTED SUCCESSFULLY',
        });

        setDisableForm(true);
      })
      .catch((e) => {
        update({
          toast_type: toast.TYPE.SUCCESS,
          custom_component: JSON.stringify(e),
        });
        console.error(e);
      });

    // for(let [key,value] in Object.entries(e.target)){
    //     console.log('submit',key,value,typeof e.target,e.target[value])

    // }
  };

  return (
    <Center height="100vh">
      <div className="App">
        <div className="testbox">
          <form onSubmit={submitForm} style={{ width: '100%' }}>
            <ReactStepForm
              steps={steps}
              setSteps={setSteps}
              noOfSteps={noOfSteps}
              components={components}
            />
            <Flex
              flexDirection={'row'}
              gap="3px"
              style={{
                marginTop: 15,
              }}>
              {steps > 0 && (
                <button
                  className="btn-block"
                  type="button"
                  onClick={() => {
                    Prev(steps, setSteps);
                  }}>
                  Prev
                </button>
              )}
              {steps < noOfSteps && (
                <button
                  className="btn-block"
                  type="button"
                  onClick={() => {
                    Next(steps, setSteps, noOfSteps);
                  }}>
                  Next
                </button>
              )}
              {steps === noOfSteps && (
                <div className="btn-block">
                  <button
                    type="submit"
                    disabled={disable_form}
                    style={{
                      background: disable_form ? '#b0afaf' : null,
                    }}>
                    Submit Form
                  </button>
                </div>
              )}
            </Flex>
          </form>
        </div>
      </div>
    </Center>
  );
}
