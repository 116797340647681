import React, { useEffect } from "react";
import { list_arr } from "./utils";
import DynamicFieldSet from "./DynamicFieldSet";
import { useStore } from "./store";
import { Box } from "@mui/material";
import { Flex } from "./Flex";
import DynamicField from "./DynamicField";

export default function StepThree({ steps, setSteps, noOfSteps }) {
  // const snap = useSnapshot(state)
  const { dynamicSetData, ...snap } = useStore((state) => ({
    ...state,
  }));

  // const unsub2 = useStore.subscribe((state) => state.third_party, console.log)

  useEffect(() => {
    // unsub2()
    if (snap?.third_party === "No" && snap.third_party_reason != null) {
      dynamicSetData("third_party_reason", null);
    }
  }, [snap?.third_party, dynamicSetData, snap?.third_party_reason]);
  useEffect(() => {
    if (
      snap?.compliance_req === "No" &&
      snap.compliance_req__explanation != null
    ) {
      dynamicSetData("compliance_req__explanation", null);
    }
  }, [snap?.compliance_req, dynamicSetData, snap.compliance_req__explanation]);
  return (
    <div
      style={{
        width: "100%",
        overflowY: "scroll",
        height: "72vh",
        padding: "21px",
      }}
    >
      <h2>Business Requirements</h2>

      <Box marginBottom={"13px"}>
        <Box width={"100%"}>
          <DynamicField
            list_form={["Chat", "Voice", "Social media", "other"]}
            legend_text={"Channels of communications supported?"}
            state_key={"channel_comm"}
          />
        </Box>
        <Flex
          flexDirection={"column"}
          width={"100%"}
          justifyContent={"space-around"}
        >
          <Box width={"100%"}>
            <DynamicField
              list_form={["Yes", "No"]}
              legend_text={
                "Do you need workforce scheduling or forecasting or capacity planning?"
              }
              state_key={"require_capacity_planning"}
              style={{
                marginTop: "13px",
              }}
              required
            />
          </Box>
          <Box width={"100%"}>
            <h4>
              What is your current Workforce, Scheduling and capacity planning
              solution?
            </h4>
            <input
              type="text"
              name="capacity_planning"
              placeholder="Verint, Calabrio, Nice..."
              onChange={(e) =>
                dynamicSetData("capacity_planning", e.target.value)
              }
              value={snap.capacity_planning}
              style={{
                width: "98%",
              }}
            />
          </Box>
        </Flex>
      </Box>
      <Flex flexDirection={'row'}>
      <Box width={"100%"}>
            <DynamicField
              list_form={["Yes", "No"]}
              legend_text={"Integration with CRM systems?"}
              state_key={"crm_system"}
              style={{
                marginTop: "8px",
              }}
              required
              additionalInfo
            />
          </Box>
          <DynamicField
            list_form={["Yes", "No"]}
            legend_text={"Do you need integration(s) with third party systems?"}
            state_key={"third_party"}
            style={{
              width: "100%",
              marginTop: "8px",
            }}
            required
            additionalInfo
          />
      </Flex>
      <Flex
        flexDirection={"row"}
        width={"100%"}
        justifyContent={"space-around"}
      >
        <Box width={"100%"}>
          <DynamicField
            list_form={["Yes", "No"]}
            legend_text={
              "Do you have any compliance requirements (e.g., HIPPA, PII, PCI etc.)?"
            }
            state_key={"compliance_req"}
            required
            additionalInfo
          />
        </Box>

        <Box width={"100%"}>
          <DynamicFieldSet
            list_form={list_arr}
            legend_text={
              "How many different call flows do you have in the current setup?"
            }
            state_key={"current_flows_count"}
            required
            flexStyleParent={{ gap: "15px" }}
          />
        </Box>
        {/* <Box width={'32%'}>
            <DynamicFieldSet
              list_form={['Yes', 'No']}
              legend_text={
                'Do you have any data security or compliance requirements?'
              }
              state_key={'data_security'}
            />
          </Box> */}
      </Flex>
      {/* <DynamicFieldSet
        list_form={[
          'customized agent console',
          'screen pops',
          'data dip into databases',
          'integration with other softwares',
          'integration with internal wikis',
          'other',
        ]}
        legend_text={'Do you need any custom integration Or development work?'}
        state_key={'custom_integration'}
      /> */}
      <Box marginBottom={"13px"}>
        <h4>
          What KPI metrics do you currently track (if don't know type dont
          know)? <span>*</span>
        </h4>
        <input
          type="text"
          className=""
          name="kpi_dashboards"
          onChange={(e) => dynamicSetData("kpi_dashboards", e.target.value)}
          value={snap.kpi_dashboards}
          style={{
            width: "98%",
          }}
        />
      </Box>
    </div>
  );
}
