// import React, { useState } from 'react'
import React from 'react';
import { Flex } from './Flex';
// import { state } from './Form';
import { Box } from '@mui/material';
import { hun_thousand } from './utils';
// import { subscribe, useSnapshot } from 'valtio';
import DynamicField from './DynamicField';
import ErrorBoundary from './ErrorBoundary';
import { useStore } from './store';

export default function StepTwo() {
  const { dynamicSetData, ...snap } = useStore((state) => ({
    ...state,
  }));
  console.log('state --> 2', snap);

  return (
    <div
      style={{
        overflowY: 'scroll',
        width: '100%',
        height: '72vh',
        padding: '21px',
      }}>
      <h2>Business Requirements</h2>
      <ErrorBoundary>
        <Flex flexDirection="column">
          <Box width={'100%'}>
            <h4>Desired Region of Amazon Connect</h4>
            <input
              type="text"
              name="desired_location"
              className=""
              placeholder="North Virginia, Oregon, Ohio..."
              onChange={(e) =>
                dynamicSetData('desired_location', e.target.value)
              }
              value={snap.desired_location}
              style={{
                width: '100%',
              }}
            />
          </Box>
          <Flex flexDirection={'row'} >
          <Box width={'100%'} marginTop={'10px'}>
            <DynamicField
              list_form={['USA', 'Outside USA','Both']}
              legend_text={'Location of the agents?'}
              state_key={'agents_location'}
              style={{
                width: '100%',
              }}
            />
            {/* <h4>Location of the agents</h4>
            <input
              type="text"
              name="agents_location"
              className=""
              onChange={(e) => (state.agents_location = e.target.value)}
              value={snap.agents_location}
              style={{
                width: '100%',
              }}
            /> */}
          </Box>
          <Box width={'100%'} marginTop={'10px'}>
            <DynamicField
              list_form={['Fixed business hours', '24x7']}
              legend_text={'Contact Center Business Hours?'}
              state_key={'business_hour'}
              style={{
                width: '100%',
              }}
              required
            />
            {/* <h4>Business hours of the contact center</h4>
            <input
              type="text"
              name="business_hour"
              className=""
              onChange={(e) => (state.business_hour = e.target.value)}
              value={snap.business_hour}
              style={{
                width: '100%',
              }}
            /> */}
          </Box>
          </Flex>
          <Box width={'100%'}>
            <h4
              style={{
                fontSize: '13px',
                fontWeight: '400',
              }}>
              Current call center vendor?
            </h4>
            <input
              type="text"
              name="current_call_center_infra"
              className=""
              placeholder="Avaya, Cisco, Genesys.."
              onChange={(e) =>
                dynamicSetData('current_call_center_infra', e.target.value)
              }
              value={snap.current_call_center_infra}
              style={{
                width: '100%',
              }}
            />
          </Box>
          <Flex flexDirection={'row'} >
          <Box width={'100%'} marginTop={'10px'}>
            <DynamicField
              list_form={['<50', '50-200', '200-500', '500+']}
              legend_text={' How many agents do you support?'}
              state_key={'agent_count'}
              style={{
                width: '100%',
              }}
              required
            />
            {/* <h4>How many agents do you support? </h4>
            <input
              type="text"
              name="agent_count"
              className=""
              onChange={(e) => (state.agent_count = e.target.value)}
              value={snap.agent_count}
              style={{
                width: '100%',
              }}
            /> */}
          </Box>
          <Box width={'100%'}  marginTop={'10px'}>
              <DynamicField
                list_form={hun_thousand.map(i => i.label)}
                legend_text={'What is your current/expected call volumes per day?'}
                state_key={'volume_calls'}
                style={{
                  marginBottom: '10px',
                }}
                required
                NorowWrap = {true}
                flexStyleParent = {
                  {
                    gap: '20px'
                  }
                }
              />
            </Box>
          </Flex>
          <Box width={'100%'}>
            <h4>
              What are the languages that you need to support?
            </h4>
            <input
              type="text"
              name="agent_support_languages"
              className=""
              placeholder="English,Spanish ..."
              onChange={(e) =>
                dynamicSetData('agent_support_languages', e.target.value)
              }
              value={snap.agent_support_languages}
              style={{
                width: '100%',
              }}
              required
            />
          </Box>
        </Flex>
        <Flex flexDirection="row">
            {/* <5, 6-20, 21-50, 51-200, 201-500 */}
            {/* </fieldset> */}
            <Box width={'100%'}>
              <DynamicField
                list_form={['Yes', 'No']}
                legend_text={'Do you need a callback feature?'}
                state_key={'callback_feature'}
                style={{
                  marginBottom: '10px',
                }}
              />
            </Box>
            <Box width={"100%"}>
          <DynamicField
            list_form={["Yes", "No"]}
            legend_text={"Do you need a voicemail feature?"}
            state_key={"voicemail_support"}
          />
        </Box>
        </Flex>
        <Flex flexDirection="row">
        <Box width={"100%"}>
          <DynamicField
            list_form={["Yes", "No"]}
            legend_text={"Do you have outbound campaigning requirements?"}
            state_key={"outbound_campaign"}
          />
        </Box>
        <Box width={"100%"}>
          <DynamicField
            list_form={["Yes", "No"]}
            legend_text={"Do you have outbound SMS and Email requirements?"}
            state_key={"outbound_sms_email"}
          />
        </Box>
        </Flex>
      </ErrorBoundary>
    </div>
  );
}
