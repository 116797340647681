import React from "react";
// import { state } from './Form';
import DynamicField from "./DynamicField";
// import { useSnapshot } from 'valtio';
import { useStore } from "./store";

export default function StepFour() {
  // eslint-disable-next-line no-unused-vars
  // const snap = useSnapshot(state);
  const { dynamicSetData, ...snap } = useStore((state) => ({
    ...state,
  }));

  console.log("snap", snap);

  // const getString = snap ? JSON.stringify(snap).slice(0,3) : ''
  return (
    <div
      style={{
        width: "100%",
        overflowY: "scroll",
        height: "72vh",
        padding: "21px",
      }}
    >
      <h2>Deployment Requirements</h2>
      <DynamicField
        list_form={["<1month", "1-3 months", ">3 months"]}
        legend_text={"When do you expect to go live?"}
        state_key={"go_live_connect"}
        required
      />
      <DynamicField
        list_form={["Yes", "No"]}
        legend_text={"Do you have any DR requirements?"}
        state_key={"dr_setup_requirements"}
        required
        additionalInfo
      />
      <DynamicField
        list_form={["Yes", "No"]}
        legend_text={"Do you have an internal IT team to support the project?"}
        state_key={"it_support"}
        required
        additionalInfo
      />
      <DynamicField
        list_form={["Yes", "No"]}
        legend_text={"Do you need Manage Services?"}
        state_key={"manage_services"}
        required
        additionalInfo
      />
      <DynamicField
        list_form={["Yes", "No"]}
        legend_text={
          "Are there any phone numbers you want to port to Amazon connect?"
        }
        state_key={"port_phn_to_amzn"}
        required
        additionalInfo
      />

      <DynamicField
        list_form={["Yes", "No"]}
        legend_text={"Do you require agent training?"}
        state_key={"requirement_training"}
        required
        additionalInfo
      />
      {/* <div style={{display: 'none'}}>{snap?.requirement_training},{snap?.port_phn_to_amzn},{snap?.it_support},{snap?.dr_setup_requirements},{snap?.go_live_connect}</div> */}
      {/* <fieldset>
              <legend>Do you want to setup Standard CCP or Custom CCP ?</legend>
              <div style = {{
                flexDirection: 'column',
display: 'flex',
justifyContent: 'center',
width: '100%'
              }}>
              <div>
                <input type="checkbox" id="ccp_with_salesforce_zendesk" name="ccp_with_salesforce_zendesk" onChange={e => state.ccp_with_salesforce_zendesk = e.target?.checked ?? false}  style = {{
                    width: '50px'
                    }} />
                <label for="scales">Do you want to integrate the CCP with Salesforce/Zendesk/etc..</label>
              </div>

              <div>
                <input type="checkbox" id="custom_app_integration" name="custom_app_integration" onChange={e => state.custom_app_integration = e.target?.checked ?? false}  style = {{
                    width: '50px'
                    }} />
                <label for="horns">Do you want to integrate with your custom application </label>
              </div>
              </div>
          </fieldset>
          <fieldset>
              <legend>What communication channels do you offer customers, for example, chat, voice, or both?</legend>

              <div>
                <input type="checkbox" id="chat" name="chat"  onChange={e => state.chat = e.target?.checked ?? false}  style = {{
                    width: '50px'
                    }} />
                <label for="scales">Chat</label>
              </div>

              <div>
                <input type="checkbox" id="voice" name="voice"  onChange={e => state.voice = e.target?.checked ?? false}  style = {{
                    width: '50px'
                    }}/>
                <label for="horns">Voice</label>
              </div>
          </fieldset>
          <h4>Do you want the contact flow to have self-service options using Amazon lex?</h4>
            <select name="use_amazon_lex" className='select_class1' onChange={e => state.use_amazon_lex = e.target.value}>
              <option class="disabled" value="location" disabled selected>*Please Select*</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          <fieldset>
              <legend>Do you want Voicemail solution in new current setup? </legend>

              <div>
                <input type="checkbox" id="connect_chat" name="connect_chat" onChange={e => state.connect_chat = e.target?.checked ?? false}  style = {{
                    width: '50px'
                    }} />
                <label for="scales">Connect chat</label>
              </div>

              <div>
                <input type="checkbox" id="sms_text" name="sms_text" onChange={e => state.sms_text = e.target?.checked ?? false}  style = {{
                    width: '50px'
                    }}/>
                <label for="horns">SMS/Text channel</label>
              </div>
          </fieldset>
          <div style={{
            marginBottom: '9px'
          }}>
          <h4>Do you want Voicemail solution in new current setup?</h4>
          <select name="voicemail_setup" className='select_class1' onChange={e => state.voicemail_setup = e.target?.checked ?? false}>
            <option class="disabled" value="location" disabled selected>*Please Select*</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          </div>
          <fieldset>
          <legend>Information</legend>
          <div>
            <input type="checkbox" id="pii_complaint" name="pii_complaint" onChange={e => state.pii_complaint = e.target?.checked ?? false}  style = {{
                    width: '50px'
                    }} />
            <label for="scales">We collect PII complaint information</label>
          </div>

          <div>
            <input type="checkbox" id="redaction" name="redaction" onChange={e => state.redaction = e.target?.checked ?? false}  style = {{
                    width: '50px'
                    }} />
            <label for="horns">We want it redacted</label>
          </div>
          </fieldset>
          <h4>Do you need Amazon Connect CASE/TASK setup for the agents?</h4>
          <select name="case_task_setup" className='select_class1' onChange={e => state.case_task_setup = e.target.value}>
            <option class="disabled" value="location" disabled selected>*Please Select*</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <h4>Are there any phone numbers you want to port to Amazon Connect?(add all phone numbers followed by ';' SEMICOLON)</h4>
          <input type="text" name="phone_numbers_to_port" onChange={e => state.phone_numbers_to_port = e.target.value} />
          <h4>What other integrations are you looking forward to with Amazon Connect?</h4>
          <input type="text" name="other_integrations" onChange={e => state.other_integrations = e.target.value} /> */}
    </div>
  );
}
