import React from "react"

export const Flex = ({
    flex,
    justifyContent,
    flexDirection,
    flexGrow,
    flexBasis,
    flexShrink,
    flexWrap,
    gap,
    alignItems,
    margin,
    padding,
    width,
    height,
    maxWidth,
    style = {},
    className,
    children
}) => (
  <div
    className={className}
    style={{
      display: 'flex',
      justifyContent: justifyContent ?? 'flex-start',
      flexDirection: flexDirection ?? 'row',
      gap: gap ?? 1,
      flexGrow: flexGrow ?? 0,
      flexBasis: flexBasis ?? 'auto',
      flexShrink: flexShrink ?? 1,
      flexWrap: flexWrap ?? 'nowrap',
      flex: flex ?? '0 1 auto',
      alignItems: alignItems ?? 'stretch',
      margin: margin ?? '0',
      padding: padding ?? '0',
      width: width ?? 'auto',
      height: height ?? 'auto',
      maxWidth: maxWidth ?? 'none',
      ...style
    }}
  >
    {children || ''}
  </div>
)