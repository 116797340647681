import React from 'react';
import { Flex } from './Flex';
import { makeid } from './utils';
import { useStore } from './store';

export default function DynamicField({
  list_form,
  legend_text,
  state_key,
  style,
  required = false,
  additionalInfo = false
}) {
  // const snap = useSnapshot(state);
  const { dynamicSetData, ...snap } = useStore((state) => ({
    ...state,
  }));
  // const newString = (char)=>   typeof char === 'string' ? char.replace(/>|</g, '') : it;
  console.log('snappp',snap)
  const forName = (char) =>
    typeof char === 'string'
      ? char.replace(/>|</g, '').slice(0, 1).trim() + makeid(4)
      : it;
  return (
    <div style={style}>
      <fieldset>
        <legend>
          {legend_text} {required && <span>*</span>}
        </legend>
        <Flex
          flexDirection={'row'}
          alignItems={'center'}
          style={{
            flexWrap: 'wrap',
          }}>
          {list_form.map((it) => {
            const new_key = forName(it);
            if (it === 'other') {
              return (
                <div
                  key={new_key}
                  style={{
                    marginLeft: '10px',
                  }}>
                  <label
                    htmlFor={`type_${new_key}`}
                    style={{
                      marginRight: '10px',
                    }}>
                    {it}:
                  </label>
                  <input
                    type={'text'}
                    name={`type_${new_key}`}
                    onChange={(e) => dynamicSetData(state_key, e.target.value)}
                    value={it === snap[state_key] ? snap[state_key] : null}
                  />
                </div>
              );
            }
            return (
              <>
                <div key={new_key}>
                  <input
                    type={it === 'other' ? 'text' : 'checkbox'}
                    name={`type_${new_key}`}
                    id={`type_${new_key}`}
                    onChange={(e) =>
                      dynamicSetData(state_key, e.target?.checked ? it : null)
                    }
                    style={{
                      width: '30px',
                    }}
                    checked={it === snap[state_key] ? true : false}
                  />
                  {it !== 'other' && (
                    <label htmlFor={`type_${new_key}`}>{it}</label>
                  )}
                </div>
              </>
            );
          })}
          {
            additionalInfo && (
              <div
                  style={{
                    marginLeft: '10px',
                  }}>
                  <label
                    htmlFor={`type_add_${state_key}`}
                    style={{
                      marginRight: '10px',
                    }}>
                    Additional Info:
                  </label>
                  <input
                    type={'text'}
                    name={`type_add_${state_key}`}
                    onChange={(e) => {
                      dynamicSetData(`${state_key}_adInfo`, e.target.value)
                    }}
                    value={snap[`${state_key}_adInfo`] ? snap[`${state_key}_adInfo`] : null}
                  />
                </div>
            )
          }
        </Flex>
      </fieldset>
    </div>
  );
}