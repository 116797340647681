import React from 'react';

const Center = (props) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: props.height ?? '100vh',
    }}>
    {props.children}
  </div>
);

export default Center;
