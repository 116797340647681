import { create } from 'zustand';

export const useStore = create((set) => ({
  first_name: null,
  last_name: null,
  email: null,
  customer_company: null,
  customer_industry: null,
  phone_number: null,
  implementation_type: null,
  desired_location: null,
  agents_location: null,
  current_call_center_infra: null,
  business_hour: null,
  agent_count: null,
  agent_support_languages: null,
  volume_calls: null,
  callback_feature: null,
  voicemail_support: null,
  outbound_campaign: null,
  outbound_sms_email: null,
  channel_comm: null,
  require_capacity_planning: null,
  capacity_planning: null,
  crm_system: null,
  compliance_req: null,
  compliance_req__explanation: null,
  // "data_security": null,
  current_flows_count: null,
  third_party: null,
  // "custom_integration": null,
  kpi_dashboards: null,
  // ai_driven_bot: null,
  // "redaction": null,
  // "reporting_analytics": null,
  // "connect_case_integration": null,
  // "voice_auth": null,
  go_live_connect: null,
  dr_setup_requirements: null,
  it_support: null,
  port_phn_to_amzn: null,
  requirement_training: null,
  manage_services: null,
  dynamicSetData: (param, value) => set(() => ({ [param]: value })),
}));