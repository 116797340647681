import React from 'react';
import { Flex } from './Flex';
import { option_list } from './utils';
import { useStore } from './store';
import FieldSetWithForm from './FieldSetWithForm';

export default function StepOne() {
  // const snap = useSnapshot(state)
  const { first_name, dynamicSetData, ...snap } = useStore((state) => ({
    ...state,
  }));

  console.log('phone_number', snap.phone_number, snap);
  return (
    <div
      style={{
        width: '100%',
        overflowY: 'scroll',
        height: '72vh',
        padding: '21px',
      }}>
      <h1
        style={{
          'text-align': 'center',
          'font-weight': '700',
          'text-decoration': 'underline',
          marginBottom: 5,
        }}>
        Connect Intake Form
      </h1>
      <h3
        style={{
          'text-align': 'center',
          'font-weight': '700',
          marginBottom: 5,
        }}>
        Welcome to the Amazon connect requirement form. Purpose of this form is
        to receive information about your contact center requirements.
      </h3>
      <h3
        style={{
          'text-align': 'center',
          'font-weight': '700',
          'text-decoration': 'underline',
          marginBottom: 5,
        }}>
        Client information
      </h3>
      <Flex
        flexDirection={'column'}
        gap="3px"
        style={{
          marginTop: '25px',
          width: '100%',
        }}>
        <label htmlFor="first_name">First Name</label>
        <input
          type="text"
          name="first_name"
          placeholder="First"
          className=""
          style={{
            width: '100%',
          }}
          onChange={(e) => dynamicSetData('first_name', e.target.value)}
          value={first_name}
        />
        <label htmlFor="last_name">Last Name</label>
        <input
          type="text"
          name="last_name"
          placeholder="Last"
          className=""
          style={{
            width: '100%',
          }}
          onChange={(e) => dynamicSetData('last_name', e.target.value)}
          value={snap.last_name}
        />
        <label htmlFor="email">
          Email <span>*</span>
        </label>
        <input
          type="text"
          name="email"
          placeholder="email"
          required
          aria-required
          style={{
            width: '100%',
          }}
          onChange={(e) => dynamicSetData('email', e.target.value)}
          value={snap.email}
        />
        <label htmlFor="company">
          Customer Company <span>*</span>
        </label>
        <input
          type="text"
          name="customer_company"
          placeholder="Customer company"
          required
          aria-required
          style={{
            width: '100%',
          }}
          onChange={(e) => dynamicSetData('customer_company', e.target.value)}
          value={snap.customer_company}
        />
        <div
          style={{
            marginBottom: '10px',
          }}>
          <label htmlFor="customer_industry"> Customer Industry</label>
          <select
            name="customer_industry"
            onChange={(event) =>
              dynamicSetData('customer_industry', event.target.value)
            }
            value={snap.customer_industry}
            className="select_class">
            <option className="disabled" value="location" disabled selected>
              *Please Select*
            </option>
            <option value="Agriculture & Forestry/Wildlife">
              Agriculture & Forestry/Wildlife
            </option>
            <option value="Business & Information">
              Business & Information
            </option>
            <option value="Construction/Utilities/Contracting">
              Construction/Utilities/Contracting
            </option>
            <option value="Education">Education</option>
            <option value="Finance & Insurance">Finance & Insurance</option>
            <option value="Food & Hospitality">Food & Hospitality</option>
            <option value="Gaming">Gaming</option>
            <option value="Health Services">Health Services</option>
            <option value="Motor Vehicle">Motor Vehicle</option>
            <option value="Natural Resources/Environmental">
              Natural Resources/Environmental
            </option>
            <option value="Personal Services">Personal Services</option>
            <option value="Safety/Security & Legal">
              Safety/Security & Legal
            </option>
            <option value="Transportation">Transportation</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <label htmlFor="phone_number">
          Phone Number
          {/* <span>*</span> */}
        </label>
        <input
          type="text"
          name="phone_number"
          placeholder="Phone Number"
          required
          aria-required
          style={{
            width: '100%',
          }}
          onChange={(e) => dynamicSetData('phone_number', e.target.value)}
          value={snap.phone_number}
        />
        <FieldSetWithForm
          list_form={option_list}
          legend_text={'Type of implementation'}
          state_key={'implementation_type'}
          required
          flexStyleParent={{
            gap: '10px'
          }}
        />
        {/* <fieldset>
            <legend>Type of implementation</legend>
                    {
            option_list.map((it) => {
                const new_prop =  it === 'other' ?  {
                  value: it === snap.implementation_type ? snap.implementation_type : null
                } : {
                  checked: it === snap.implementation_type ? snap.implementation_type : false
                }
      return (
        <>
        <div key={it}>
        {  it === 'other' &&  <label htmlFor={`implementation_type_${it}`} style={{
          marginRight:  '10px'
        }}>{it}:</label>}
        <input type={ it === 'other' ? "text" :"checkbox"}  name={`implementation_type_${it}`}  onChange={e => state.implementation_type =  it === 'other' ? e.target.value : e.target?.checked ? it : null}
        {
          ...new_prop
        }
        style = {{
                    width:  it === 'other' ? null :'50px'
                    }} />
      {  it !== 'other' &&  <label htmlFor={`implementation_type_${it}`}>{it}</label>}
        </div>

        </>
      )
    })
            }
            </fieldset> */}
      </Flex>
    </div>
  );
}
