import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import reportWebVitals from './reportWebVitals';
import Form from './Form';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Form />
    <ToastContainer
      autoClose={7000}
      closeOnClick={true}
      draggable={true}
      hideProgressBar={false}
      newestOnTop={false}
      pauseOnFocusLoss={true}
      pauseOnHover={true}
      position="bottom-center"
      rtl={false}
      theme="colored"
    />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
